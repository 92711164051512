<template>
  <div class="mt-8 mx-8">

    <v-card class="d-flex justify-space-between " flat>

      <v-card flat class="pl-3 title text--secondary font-weight-bold">
        <v-icon>mdi-account</v-icon> 
      </v-card>
      <v-card flat>
        <v-tabs right color="orange darken-1">
          <v-tab class="font-weight-bold" ripple :to="{ name:'changePassword' }">CHANGE PASSWORD</v-tab>
          <svg width="15" class="mx-2">
            <line x1="15" y1="0" x2="0" y2="50" style="stroke:rgb(251,140,0);stroke-width:2" />
          </svg>
        </v-tabs>
      </v-card>
    </v-card>

    <v-container>
          <v-slide-x-transition mode="out-in">

      <router-view />
          </v-slide-x-transition>
    </v-container>
  </div>

</template>

<script>

  export default {
    name: 'ManageProfile',
  }
</script>
